<template>
  <div class="component-area">
    <el-row :gutter="20" type="flex" justify="space-between">
      <el-col :span="8">
        <el-select v-model="shengstr" placeholder="省" @change="changesheng">
          <el-option
            :label="item.name"
            :value="item.name"
            v-for="(item, i) in sheng"
            :key="i"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="8">
        <el-select v-model="shistr" placeholder="市" @change="changeshi">
          <el-option
            :label="item.name"
            :value="item.name"
            v-for="(item, i) in shi"
            :key="i"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="8">
        <el-select v-model="qustr" placeholder="区" @change="changequ">
          <el-option
            :label="item.name"
            :value="item.name"
            v-for="(item, i) in qu"
            :key="i"
          ></el-option>
        </el-select>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: {
    value: {
       type: String,
    },
  },
  data() {
    return {
      sheng: [],
      shi: [],
      qu: [],
      shengstr: "",
      shistr: "",
      qustr: "",
     
    };
  },
 mounted(){
  this.$http.getareas().then((res) => {
      this.sheng = res.data;
    });
 },
  methods: {
   changesheng(val) {
      var index = this.sheng.findIndex((obj) => obj.name == val);
      this.shi = this.sheng[index].children;
      this.qu = [];
      this.shistr = "";
      this.qustr = "";
      this.getcompany_ssc();
    },
    changeshi(val) {
      var index = this.shi.findIndex((obj) => obj.name == val);
      this.qu = this.shi[index].children;
      this.qustr = "";
      this.getcompany_ssc();
    },
    changequ(val) {
      this.getcompany_ssc();
    },
    getcompany_ssc() {
      var str="";
      if (this.shengstr) {
         str = this.shengstr;
      }
      if (this.shistr) {
        str += " " + this.shistr;
      }
      if (this.qustr) {
         str += " " + this.qustr;
      }
     this.$emit("input", str);
    },
    
  },
};
</script>
<style scoped lang="scss">
// .el-upload--picture-card 控制加号部分
.el-select {
  width: 100%;
}
</style>
